// Imports
import Splide from '@splidejs/splide';

class Carousels {
    constructor() {
    }

    // Initialize the appear functionality
    init() {
        const carouselEls = document.querySelectorAll('.carousel');
        carouselEls.forEach((carousel, i) => {
            let splide = new Splide(carousel, {
                gap: 0,
                pagination: false,
                perPage: 1,
                type: 'loop',
            }).mount();

            let carouselCounter = carousel.querySelector('.carousel-counter');

            function updateCounter() {
                carouselCounter.textContent = splide.index + 1; // Splide index is zero-based
            }

            splide.on('moved', updateCounter);

            // Initialize counter on load
            updateCounter();
        });
    }
}

export default new Carousels();
