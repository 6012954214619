/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// App imports
import barba from '@barba/core';
import gsap from 'gsap';
import htmx from 'htmx.org';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';

// Project imports
// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import Calcs from '@js/helpers/Calcs';

// Project modules
import Appear from '@js/modules/Appear';
import AppearGrid from '@js/modules/AppearGrid';
import ExternalLinks from '@js/modules/ExternalLinks';
import Page from '@js/modules/Page';
import PageScrolling from '@js/modules/PageScrolling';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import ProgressLine from '@js/modules/ProgressLine';
import ScrollTo from '@js/modules/ScrollTo';
import SectionCarousels from '@js/modules/SectionCarousels';

// Alpine
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.start();

// HTML element classlist
const HTML_CLASSLIST = document.documentElement.classList;
const BODY_CLASSLIST = document.body.classList;
const PAGE_TRANSITION_SPEED = 0.7;

window.APP =
    window.APP ||
    new (function APP() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            GoToScrollPos: false,
            ModalOpenClass: '_modal-open',
            ScrollYPos: 0,
            PreviousUrl: null,
        };

        this.modules = {
            PageScrolling: PageScrolling,
            PageScrollingManager: PageScrollingManager,
            SectionCarousels: SectionCarousels,
        };

        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

        // Pages
        this.pages = {

        };

        const popstateClass = '_popstate';

        let currentController;
        let currentClasses;
        let currentNameSpace;

        const beforeController = (data, done) => {
            console.log('beforeController');
            done();
        };

        const afterLeaveController = (data, done) => {
            console.log('afterLeaveController');
            done();
        };

        const leaveController = (container, done) => {
            HTML_CLASSLIST.add('_page-loading');
            HTML_CLASSLIST.remove('_page-loaded');

            // Stop scroll
            PageScrolling.lock(document.body);

            // Show progress line
            ProgressLine.show();

            // Fade out container
            gsap.to(container, {
                duration: PAGE_TRANSITION_SPEED,
                alpha: 0,
            });

            // Clear appear animations
            AppearGrid.destroy();

            // Leave the current page if exists
            currentController &&
            currentController.leave &&
            currentController.leave();

            done();
        };

        const enterController = (container, firstCall = false) => {
            // Scroll to top
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: firstCall ? 'instant' : 'auto',
            });

            // Enable scrolling
            PageScrolling.unlock(document.body);

            // Modules
            ScrollTo.build();

            if (!firstCall) {
                ProgressLine.hide();
            }

            // Appear animations
            Appear.init();
            AppearGrid.init();

            PageScrolling.start();
            PageScrollingManager.update(true);

            // Processes new content, enabling htmx behavior
            if (!firstCall) {
                htmx.process(document.body);
            }

            // Vars
            const nameSpace = container.getAttribute('data-spa-namespace');
            let pageTheme = container.getAttribute('data-spa-theme');
            let footerTheme = container.getAttribute('data-spa-footer-theme');

            // Default theme to light
            if (pageTheme === null) {
                pageTheme = 'light';
            }

            // Set page theme
            if (pageTheme == 'dark') {
                //Dark BG

                // Update body BG
                BODY_CLASSLIST.remove('theme-light');
                BODY_CLASSLIST.add('theme-dark');
            }
            else {
                // Light BG

                // Update body BG
                BODY_CLASSLIST.remove('theme-dark');
                BODY_CLASSLIST.add('theme-light');
            }

            const FOOTER = document.querySelector('.mn-ftr');
            if (FOOTER) {
                const FOOTER_CLASSLIST = FOOTER.classList;

                // Default foother theme to dark
                if (footerTheme === null) {
                    footerTheme = 'dark';
                }

                // Set footer theme
                if (footerTheme == 'dark') {
                    //Dark BG

                    // Update body BG
                    FOOTER_CLASSLIST.remove('theme-light');
                    FOOTER_CLASSLIST.add('theme-dark');
                } else {
                    // Light BG

                    // Update body BG
                    FOOTER_CLASSLIST.remove('theme-dark');
                    FOOTER_CLASSLIST.add('theme-light');
                }
            }

            // Page controller
            const pageController = this.pages[nameSpace.toLowerCase()];
            if (pageController) {
                // Run page JS
                currentController = new pageController(container);
            }
            else {
                currentController = null;
            }

            // Test for old classes - if any, remove them
            if (currentClasses) {
                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.remove(cssClass);
                });
            }
            // Get new classes - if any, add them
            currentClasses = (container.getAttribute('data-spa-class') || null);
            if (currentClasses) {
                currentClasses = currentClasses.split(' ');

                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.add(cssClass);
                });
            }

            currentNameSpace && HTML_CLASSLIST.remove(currentNameSpace);
            currentNameSpace = '_page-' + nameSpace;
            HTML_CLASSLIST.add(currentNameSpace);

            HTML_CLASSLIST.remove('_page-loading');
            HTML_CLASSLIST.add('_page-loaded');

            // Update active links on page
            // Supports sections pages under sections
            Array.from(document.querySelectorAll('a:not(.exclude-active)')).forEach((link) => {
                if (
                    this.utils.testHrefIsActual(link.getAttribute('href'))
                    || window.location.href.includes(link.href)
                ) {
                    link.classList.add('_active');
                }
                else {
                    link.classList.remove('_active');
                }
            });

            // Update active state on nav buttons
            Array.from(document.querySelectorAll('.nav-btn')).forEach((btn) => {
                let dataHref = btn.getAttribute('data-href');

                if (
                    this.utils.testHrefIsActual(dataHref)
                    || window.location.href.includes(dataHref)
                ) {
                    btn.classList.add('_active');
                }
                else {
                    btn.classList.remove('_active');
                }
            });

            // Mark external links
            this.helpers.DelayedCall.create(1000, () => {
                ExternalLinks.build();
            });

            if (!firstCall) {
                gsap.from(container, {
                    duration: PAGE_TRANSITION_SPEED,
                    alpha: 0
                });
            }
        };

        const afterController = (next) => {
            if (this.helpers.GoToScrollPos) {
                console.log('APPLY SCROLL');
                console.log(this.helpers.ScrollYPos);
                window.scrollTo(0, this.helpers.ScrollYPos);

                this.helpers.GoToScrollPos = false;
            }

            this.helpers.PreviousUrl = barba.history.previous.url;

            // Reinit page functionality
            Page.init();

            if (HTML_CLASSLIST.contains(popstateClass)) {
                HTML_CLASSLIST.remove(popstateClass);
            }
        };

        const start = () => {
            HTML_CLASSLIST.remove('_page-loading');

            enterController(document.body.querySelector('[data-spa-namespace]'), true);

            HTML_CLASSLIST.add('_page-loaded');

            Page.init();

            /**
             * Init Barba
             */
            barba.init({
                schema: {
                    prefix: 'data-spa',
                },
                sync: true,
                debug: false,
                prefetchIgnore: false,
                cacheIgnore: true,
                preventRunning: true,
                requestError: (trigger, action, url, response) => {
                    // Go to a custom 404 page if the user click on a link that return a 404 response status
                    if (action === 'click' && response.status && response.status === 404) {
                        barba.go('/404');
                    }

                    // Prevent Barba from redirecting the user to the requested URL
                    // this is equivalent to e.preventDefault() in this context
                    return false;
                },
                prevent: ({ el, event }) => {
                    event.stopPropagation();

                    console.log(el.getAttribute('data-external'));

                    if (
                        el.getAttribute('data-external') != null ||
                        el.getAttribute('target') === '_blank' ||
                        el.getAttribute('type') === 'submit'
                    ) {
                        console.log('External link');
                        return true;
                    }

                    console.log('Internal link');

                    // Test if link is an anchor
                    if (el.getAttribute('href').startsWith('#')) {
                        console.log('Link is an anchor');
                        return true;
                    }
                    else {
                        // Is internal page link - Let Barba JS deal with it
                        event.preventDefault();
                        return false;
                    }
                },
                timeout: 30 * 1000,
                transitions: [{
                    async before(data) {
                        console.log('BEFORE');

                        const done = this.async();

                        beforeController(data, done);
                    },
                    async leave(data) {
                        console.log('LEAVE');
                        const done = this.async();

                        console.log('data');
                        console.log(data);

                        leaveController(data.current.container, done);
                    },
                    async afterLeave(data) {
                        console.log('AFTERLEAVE');
                        const done = this.async();

                        afterLeaveController(data, done);
                    },
                    async enter({ data, current, next }) {
                        console.log('ENTER');
                        console.log({data});

                        console.log('HISTORY');
                        console.log(barba.history);
                        console.log(barba.history.previous.url);


                        enterController(next.container);
                    },
                    async after({ data }) {
                        console.log('AFTER');

                        afterController();

                        // GA page view tracking
                        if (typeof gtag === 'function') {
                            gtag('event', 'page_view');
                        }
                    }
                }]
            });
        };


        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Start SPA
            start();

            // Overrides the request class (default is `htmx-request`)
            htmx.config.requestClass = 'loading';

            // Reinit search after swap
            htmx.on('htmx:afterSettle', (event) => {
                // window.reinitSearch();
                Page.init();
            });
        });

        // Popstate
        window.addEventListener('popstate', (event) => {
            // Log the state data to the console
            HTML_CLASSLIST.add(popstateClass);

            // Enable scrolling
            PageScrolling.clearLocks();
        });
    })();
