// Imports
import { gsap } from 'gsap';

class Navigation {
    constructor() {
        /**
         * Desktop nav
         */
        this.navDropdownAttr = 'data-nav-dropdown';
        this.openDropdown = null;
        this.isOpen = false;

        document.querySelectorAll(`[${this.navDropdownAttr}]`).forEach((dropdown) => {
            const id = dropdown.getAttribute(this.navDropdownAttr);
            const button = dropdown.querySelector('.nav-btn');
            const panel = document.querySelector(`[data-nav-dropdown-panel="${id}"]`);
            const container = document.querySelector(`[data-nav-dropdown-container="${id}"]`);

            const toggleDropdown = () => {
                if (this.isOpen && this.openDropdown === id) {
                    // Close the dropdown
                    gsap.to(panel, {
                        duration: 0.3,
                        y: '-100%',
                        onComplete: () => {
                            panel.style.display = 'none';
                            gsap.to(container, { duration: 0, opacity: 0 });
                        }
                    });

                    button.setAttribute('aria-expanded', 'false');
                    this.openDropdown = null;
                    this.isOpen = false;
                } else {
                    // Close any other open dropdowns
                    if (this.openDropdown) {
                        const openPanel = document.querySelector(`[data-nav-dropdown-panel="${this.openDropdown}"]`);
                        const openContainer = document.querySelector(`[data-nav-dropdown-container="${this.openDropdown}"]`);
                        const openButton = document.querySelector(`[data-nav-dropdown="${this.openDropdown}"] .nav-btn`);

                        gsap.to(openPanel, {
                            duration: 0.3,
                            y: '-100%',
                            onComplete: () => {
                                openPanel.style.display = 'none';
                                gsap.to(openContainer, { duration: 0, opacity: 0 });
                            }
                        });

                        openButton.setAttribute('aria-expanded', 'false');
                    }

                    // Open the current dropdown
                    panel.style.display = 'block';
                    gsap.fromTo(panel, { y: '-100%' }, { duration: 0.3, y: '0%' });
                    gsap.to(container, { opacity: 1, duration: 0.3 });

                    button.setAttribute('aria-expanded', 'true');
                    this.openDropdown = id;
                    this.isOpen = true;
                }
            };

            // Close buttons inside the dropdown
            panel.querySelectorAll('.close-dropdown').forEach((dropdownButton) => {
                dropdownButton.addEventListener('click', () => {
                    toggleDropdown(); // Close the dropdown when any button inside it is clicked
                });
            });

            button.addEventListener('click', toggleDropdown);

            // Close when clicking outside
            document.addEventListener('click', (e) => {
                const clickedInsidePanel = panel.contains(e.target);
                const clickedInsideButton = button.contains(e.target);

                if (!clickedInsidePanel && !clickedInsideButton && this.isOpen && this.openDropdown === id) {
                    toggleDropdown();
                }
            });

            // Close dropdown if window is resized below 1024px
            window.addEventListener('resize', () => {
                if (window.innerWidth < 1024 && this.isOpen) {
                    toggleDropdown();
                }
            });
        });
    }
}

export default new Navigation();
