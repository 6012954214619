class SectionTitle {
    constructor() {
    }

    // Initialize the appear functionality
    init() {
        this._setup();
        window.addEventListener('resize', this._setup);
    }

    // Clear the appear functionality
    _setup() {
        const sectionBtns = document.querySelectorAll('.section-btns');

        sectionBtns.forEach(container => {
            const buttons = container.querySelectorAll('.section-btn');

            buttons.forEach(button => {
                button.addEventListener('click', () => {
                    // Get the button's position relative to the tab menu
                    const buttonPosition = button.offsetLeft;
                    // Scroll to the button's position
                    container.scrollLeft = buttonPosition;
                });
            });
        });
    }
}

export default new SectionTitle();
