import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class ScrollTo {
    constructor() {

    }
    build() {
        const attrName = 'data-scroll-to';

        Array.from(
            document.querySelectorAll('[' + attrName + ']') || []
        ).forEach((element) =>
            element.addEventListener('click', (e) => {
                e.preventDefault();
                this.scrollTo(e.currentTarget.getAttribute(attrName));
            })
        );
    }
    scrollTo(attrs) {
        const attribute = attrs.split(',');

        let y = attribute[0].trim();
        let offset = attribute[1] ? attribute[1].trim() : 0;

        if (!this._isNum(y)) {
            let targetElement;

            if (typeof y === 'string') {
                targetElement = document.querySelector(y);

                // If no element jump out
                if (!targetElement) {
                    console.error('No element found on the page');
                    return;
                }
            }
            else {
                targetElement = y;
            }

            y = targetElement.getBoundingClientRect().top + (window.pageYOffset || document.body.scrollTop);
        }

        y = y + offset;

        y = y < 0 ? 0 : y;

        // Grab the prefers reduced media query.
        const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

        const scrollOptions = {
            top: y,
            left: 0,
            behavior: prefersReducedMotion ? 'auto' : 'smooth',
        };

        // Scroll by the specified amount
        window.scrollBy(scrollOptions);
    }
    _isNum(val){
        return !isNaN(val);
    }
}

export default new ScrollTo();
