// Imports
import Alpine from 'alpinejs';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

class MobileNavigation {
    constructor() {
        this.mobileNav = document.getElementById('mobile-nav');

        // Set up Alpine store to control nav toggle
        Alpine.store('mobilenav', {
            isOpen: false,
            subOpen: null,

            toggle() {
                if (this.isOpen) {
                    this.close();
                }
                else {
                    this.open();
                }
            },
            open() {
                // Open nav
                this.isOpen = true;

                // Disable scroll
                if (!document.documentElement.classList.contains('ios')) {
                    PageScrolling.lock(this.mobileNav);
                }
            },
            close() {
                // Close nav
                this.isOpen = false;
                // Close sub nav
                this.subOpen = null;

                // Enable scroll
                if (!document.documentElement.classList.contains('ios')) {
                    PageScrolling.unlock(this.mobileNav);
                }
            }
        });

        // Escape key press
        document.onkeydown = (e) => {
            // Escape key maps to keycode `27`
            if (e.key === "Escape") {
                if (Alpine.store('mobilenav').isOpen == true) {
                    Alpine.store('mobilenav').close();
                }
            }
        };

        window.onresize = function(event) {
            // Window smaller than breakpoint
            if (window.innerWidth > 1024) {
                if (Alpine.store('mobilenav').isOpen == true) {
                    Alpine.store('mobilenav').close();
                }
            }
        };
    }
}

export default new MobileNavigation();



