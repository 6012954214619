class Calcs {
	constructor() {
        this.calulate();

        document.addEventListener('DOMContentLoaded', e => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('load', () => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        const header = document.querySelector('.mn-hdr');

        /* View height */
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh.toFixed(2)}px`);

        /* Header height */
        if (header) {
            // Get the header element height
            let hh = header.offsetHeight;
            // Then we set the value in the --hh custom property to the root of the document
            document.documentElement.style.setProperty('--hh', `${hh}px`);
        }

        /* Section width */
        // Get the current viewport width, capped at 1700px
        const viewportWidth = Math.min(window.innerWidth, 1700);
        // Divide by 3
        const sw = viewportWidth / 3;
        // Then we set the value in the --section-w custom property to the root of the document
        document.documentElement.style.setProperty('--section-w', `${sw}px`);
    }
}

export default new Calcs();
