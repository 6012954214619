// Imports
import utils from '@js/utils/utils';

class StatsCalc {
    constructor() {
        this.blockSelector = '.block-stats';
        this.statSelector = '.stat';
        this.resizeTimeout = null;
        this.isMobile = window.innerWidth < 768;

        this.calculate();

        // We listen to the resize event and debounce it
        window.addEventListener('resize', utils.debounce(() => {
            const viewportWidth = window.innerWidth;
            const isNowMobile = viewportWidth < 768;

            // Only recalculate if we're switching between mobile and desktop
            if (isNowMobile !== this.isMobile) {
                this.isMobile = isNowMobile;
                this.calculate();
            }
        }, 150));
    }

    calculate() {
        if (this.isMobile) {
            this.applyMaxWidth();
        } else {
            this.reset();
        }
    }

    applyMaxWidth() {
        // Reset existing inline styles before recalculating
        this.reset();

        // Get elements
        const elements = document.querySelectorAll(this.blockSelector);

        // Loop through elements
        elements.forEach(el => {
            let maxWidth = 0;

            // Find the largest width in the current block
            const stats = el.querySelectorAll(this.statSelector);
            stats.forEach(stat => {
                maxWidth = Math.max(maxWidth, stat.offsetWidth);
            });

            // Apply the largest width to all elements
            requestAnimationFrame(() => {
                stats.forEach(stat => {
                    stat.style.width = `${maxWidth}px`;
                });
            });
        });
    }

    reset() {
        const elements = document.querySelectorAll(`${this.blockSelector} ${this.statSelector}`);
        elements.forEach(el => {
            el.style.width = '';
        });
    }
}

export default new StatsCalc();
